<template>
  <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
        <vue-good-table 
            id="roleList" 
            :columns="computedColumns"
            :rows="rows"
            :isLoading="loadingStates.fetchingRoles"
            :pagination-options="{ enabled: true, dropdownAllowAll: false,}"
            @on-page-change="onPageChange($event)"
            @on-per-page-change="onPageChange($event, true)"
        >
          <template #column-filter="{ column, updateFilters }">
                <dropdown
                    ref="setFilterRef"
                    reposition
                    searchable
                    class="filter-dropdown"
                    placeholder="Select"
                    v-if="column.filterOptions && column.filterOptions.enabled && column.config && !['indicators', 'allocation'].includes(column.config.type)"
                    :options="column.filterOptions.filterDropdownItems"
                    :value="getFilterValue(column)"
                    :limit="1"
                    maxWidth="100%"
                    width="100px"
                    minWidth="100%"
                    :config="{ label: 'name', trackBy: 'id' }"
                    :multiple="false"
                    :taggable="false"
                    @input="(value) => updateColumnFilters(column, value, updateFilters)"
                    @search="searchFilter(column, $event)"
                >
                    <template #caret>
                      <sort-icon class="fill-current" />
                    </template>
                </dropdown>
                <div class="" v-else>
                    <!-- // adding a blank div to remove the default template   -->
                </div>
            </template>
            <template slot="table-row" slot-scope="props">
                <div
                  v-if="props.column.field === 'role'" :class="[props.row.system_default && 'default hover-text']"
                  class="flex gap-3"
                >
                    <router-link
                      :to="{
                            name: 'roles-and-permissions-details',
                            params: { id: props.row.id },
                        }"
                      class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                      {{ props.row.role }}
                    </router-link>
                </div>
                <div
                  v-else-if="props.column.field === 'permissions_count'"
                  class="flex gap-3 items-center justify-center  text-blue-500"
                >
                    <span
                      v-if="props.row.permissions_count > 0"
                      class="cursor-pointer"
                      @click="showList(props.row, 'Permissions' )"
                    >
                      {{props.row.permissions_count }} Permissions
                    </span>
                    <span v-else class="text-gray-600 evnt">
                        {{props.row.permissions_count }} Permissions
                    </span>
                </div>
                <div v-else-if="props.column.field === 'users_count'"
                    :class="[props.row.system_default && 'default hover-text']"
                    class="flex gap-3 items-center justify-center">
                    <div class="relative"
                        :class="props.row.users_count ? 'cursor-pointer text-primary' : 'text-gray-500' "
                        @click=" props.row.users_count > 0 && showList(props.row, 'Users' )">
                        <div class="p-1 absolute left-4 -top-2 rounded-full  text-white text-xxs flex items-center justify-center"
                            :class="props.row.users_count ? 'bg-primary' : 'bg-gray-500' ">
                            {{props.row.users_count }}</div>
                        <font-awesome-icon icon="user" class="text-current" />
                    </div>
                </div>
                <div v-else-if="props.column.field === 'groups_count'"
                    :class="[props.row.system_default && 'default hover-text']"
                    class="flex gap-3 items-center justify-center">
                    <div class="relative"
                        :class="props.row.groups_count ? 'cursor-pointer text-primary' : 'text-gray-500' "
                        @click=" props.row.groups_count > 0 && showList(props.row, 'Groups' )">
                        <div class="p-1 absolute left-4 -top-2 rounded-full text-white text-xxs flex items-center justify-center"
                            :class="props.row.groups_count ? 'bg-primary' : 'bg-gray-500' ">
                            {{props.row.groups_count }}</div>


                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5"
                            :fill="props.row && props.row.groups_count > 0 ? 'var(--theme-color-main)' : 'gray'"
                            viewBox="0 0 640 512">
                            <path
                                d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z" />
                        </svg>

                    </div>
                </div>
                <div v-else-if="props.column.field === 'actions'"
                    :class="[props.row.system_default && 'default hover-text']" class="flex justify-center gap-3">
                    <button
                      @click="handleRoleEditClick(props.row.role_id)"
                      class="h-6 w-6 disabled:opacity-50 "
                      :class="loadingStates.deleteRole && 'pointer-events-none cursor-wait' "
                      :disabled="loadingStates.deleteRole"
                    >
                      <font-awesome-icon icon="edit" class="text-green-600 text-lg" />
                    </button>

                    <button
                      v-allow="'role.delete'"
                      class="h-6 w-6 disabled:opacity-50 "
                      :class="loadingStates.deleteRole && 'pointer-events-none cursor-wait' "
                      :disabled="loadingStates.deleteRole"
                      @click="deleteRole(props.row.role_id)"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="text-red-600 hover:text-red-700">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
            </template>
            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>
        </vue-good-table>
        <button 
                v-if="isFilters"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute  left-2 top-11 mt-6 z-50"
                @click="clearFilters"
              >
                Clear filters
            </button>
    </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import { format , parse } from "date-fns";
import Loader from "@shared/loader";
import dropdown from "@shared/components/dropdown-base";
import { onlyUnique } from "@shared/plugins/utils.js";
import { FormatDateNew } from "@/plugins/functions";

export default {
  name: "RolesTable",

  components: {
    // DatePicker,
    Loader,
    dropdown,
  },

  props: {
    rows: {
      type: Array,
      required: true,
    },
    serverParams: {
      type: Object,
      default: () => {},
    },
    loadingStates: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      columns: [
            {
                label: "Role",
                field: "name",
                config: {
                    filter: true,
                    alignCenter: true,
                    field_type: 'action_button',
                },
            },
            {
                label: "Permission",
                field: "permissions_count",
            },
            {
                label: "Users",
                field: "users_count",
            },
            {
                label: "Group",
                field: "groups_count",
            },
            {
                label: "Type",
                field: "type",
                config: {
                    filter: true,
                    alignCenter: true,
                    field_type: 'action_button',
                },
            },
            {
                label: "Action",
                field: "actions",
            },
      ],
      searchLoading: {},
    };
  },

  computed: {
    isFilters() {
      return this.serverParams && Object.entries(this.serverParams).length;
    },
    computedColumns() {
            // use option list from server if it is:
            const options = this.columnFilterOptions;
            // had to check for empty object
            if (options && Object.entries(options).length) {
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                if (col.prefetchedOptions && col.prefetchedOptions.length) {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: col.prefetchedOptions,
                    },
                  };
                } else {
                  return {
                    ...col,
                    filterOptions: {
                      enabled: filtered,
                      filterDropdownItems: options[col.field],
                    },
                  };
                }
              });
            } else {
              // TODO remove/rework default filters
              return this.columns.map((col) => {
                const filtered = col.config?.filter ?? true;
                return {
                  ...col,
                  filterOptions: filtered
                    ? {
                      enabled: true,
                      placeholder: "All",
                      filterDropdownItems: this.rows
                        .filter((row) => row[col.field])
                        .map((row) => ({ id: row.field_type_id ?? uuid.v4(), name: row[col.field] }))
                        .filter(onlyUnique),
                    }
                    : undefined,
                };
              });
            }
          },
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },
    columnOption() {
            return this.columns.map((el) => ({
              ...el,
              config: 
              {
                  ...el.config,
                  isLoading: !!this.searchLoading[el.field] || false,
              },
            }));
    },
  },
  
  methods: {
    onPageChange() {
      this.$emit("onPageChange")
    },

    showList(row, type) {
      this.$emit("onShowList", { row, type })
    },

    handleRoleEditClick(role_id) {
      this.$emit("onEditRole", role_id)
    },

    deleteRole(role_id) {
      this.$emit("onDeleteRole", role_id)
    },

    updateColumnFilters(column, value /* , callback */) {
            let filterValObj = {
              ...this.serverParams,
              [column.query_key || column.field]: value ? {
                ...value,
                field_type: column.field_type,
              } : null,
            };
            if (!filterValObj[column.query_key || column.field]) {
              delete filterValObj[column.query_key || column.field];
            }
            if (column.query_key && ["from_due_date", "from_created_at"].includes(column.query_key)) {
              let date = DateTime.fromISO(value.name).toISODate()
              if (date == null) {
                const parsedDate = parse(value.name, 'd MMM yyyy', new Date());
                date = format(parsedDate, 'yyyy-MM-dd');
                if (filterValObj.from_created_at) {
                  filterValObj.from_created_at.name = date.toString().substring(10, 0)
                } else {
                  filterValObj.from_due_date.name = date.toString().substring(10, 0)
                }
              }
              Object.assign(filterValObj, {
                [`to_${column.field}`]: {
                  id: date.ts,
                  name: date.toString().substring(10, 0),
                  field_type: column.field_type
                },
              });
            }
            if (column.config && /* column.config.query_keys && */ column.config.filter_type === 'date') { // uncommment when you want to add customkeys for backend filters
              let [start, end] = value.map(el => DateTime.fromJSDate(new Date(el)))
              Object.assign(filterValObj, {
                [column.config.query_keys['start']]: {
                  id: start.ts,
                  name: value[0] ? start.toString() : null,
                  field_type: column.field_type
                },
              })
              Object.assign(filterValObj, {
                [column.config.query_keys['end']]: {
                  id: end.ts,
                  name: value[1] ? end.toString() : null,
                  field_type: column.field_type
                },
              })
            }
            this.onColumnFilter( {
              columnFilters: filterValObj,
            });
        },

        getFilterOptions(columns, row) {
            return columns.reduce((result, curr) => {
              result[curr.field] = row
                .filter((row) => row[curr.field])
                .map((row) => row[curr.field])
                .filter(onlyUnique)
                .map((el) => (
                  { 
                    id: uuid.v4(), name: el 
                  }));
                  if (curr?.field == 'order_date' || curr?.field == 'sale_date') {
                    result[curr.field] = result[curr.field]?.map(el => {
                      return {
                        ...el,
                        name: FormatDateNew(new Date(el.name)),
                        label: el.name
                      }
                    })
                  }
                  return result;
                }, 
            {});
        },

        getFilterValue(col) {
            // isFilters ? filters[column.field] : null
            if (this.isFilters) {
              return this.serverParams[col.field] ?? this.serverParams[col.query_key];
            } else return null;
        },
        searchFilter(column, value) {
            this.$emit("onSearchFilter", { column, value });
        },
        clearFilters() {
          this.$emit("onClearFilters");
        },
        onColumnFilter({ columnFilters }) {
            this.$emit("onColumnFilter", { columnFilters });
        },
  }
}
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;

.filter-dropdown {
    @apply border-transparent bg-white;
}
</style>