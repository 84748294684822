<template>
  <div>
    <RolesTable
      :rows="rows"
      :serverParams="serverParams"
      :loadingStates="loadingStates"
      @onShowList="showList"
      @onPageChange="onPageChange"
      @onColumnFilter="onColumnFilter"
      @onSearchFilter="searchFilter"
      @onClearFilters="clearFilters"
      @onEditRole="handleRoleEditClick"
      @onDeleteRole="deleteRole"
    />

    <ModalConfirm ref="confirm-popup" />

    <AddRoleAndPermissionModal
      height="90%"
      ref="add-role-permission-modal"
      :permissionsList="getPermissionsList"
      :loadingStates="loadingStates"
      @handleSubmit="createRole($event)"
      @viewPermissionsInfo="viewPermissionsInfo($event)"
      @selectAllPer="selectAllPer($event)"
    />

    <ListModal
      :modalTitle="currentActiveModalList.title"
      :isLoading="loadingStates.listModal"
      :lists="getModalList"
      ref="roles-permission-user-group-modal"
      @handleFilterResult="handleFilterResult($event)"
    />

    <Drawer ref="drawer">
      <div class="bg-gray-100 text-base-content px-6 py-2 text-lg font-semibold flex items-center gap-3">
        <button
          class="h-6 w-6 flex justify-center cursor-pointer rounded-full items-center hover:bg-gray-300 text-gray-600 font-semibold"
          @click="closeDrawer"
        >
          <font-awesome-icon icon="times" />
        </button>
        <span>Permission Information</span>
        <span v-if="currentPermission && currentPermission.name" class="text-primary">
          " {{currentPermission && currentPermission.name}} "
        </span>
      </div>
     <div class="m-6">
        <h2 class="text-gray-600"> {{currentPermission && currentPermission.description}}</h2>
      </div>
    </Drawer>
  </div>
</template>

<script>
import axios from "@/axios";
import RolesTable from "./RolesTable.vue";
const AddRoleAndPermissionModal = () => import("./add-role-permission-modal.vue");
const ModalConfirm = () => import("@shared/modal-confirm");
const ListModal = () => import("@shared/components/listModal");
const Drawer = () => import("@shared/components/drawer");
import {
  makeQueryParams,
  getPermissions,
  selectAllChildPermissions,
} from "./utils/functions.js";
import { adminList } from "./utils/constants";

export default {
  name: "RolesAndPermissionsContainer",

  components: {
    RolesTable,
    AddRoleAndPermissionModal,
    ModalConfirm,
    ListModal,
    Drawer,
  },

  props: {
    adminPanel: {
      type: String,
      default: adminList.TENANT,
    },
    clientId: {
      type: String,
      default: "",
    },
    roleId: {
      type: String,
      default: null,
    },
    showAddRoleModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rows: [],
      loadingStates: {
        fetchingRoles: false,
        createRole: false,
        deleteRole: false,
        permission: false,
        listModal: false,
      },
      permissionsList: [],
      currentActiveModalList: {
        lists: [],
        title: "",
      },
      serverParams: {},
      currentPageData: {
        page: 1,
        limit: 10,
      },
      searchValues: "",
      currentPermission: null,
    };
  },

  computed: {
    baseApiURL() {
      return this.isTenant ? "tenant-role" : `client-role/${this.clientId}`;
    },
    baseUrlPostfix() {
      return this.isTenant ? "/" : ""
    },
    isTenant() {
      return this.adminPanel === adminList.TENANT;
    },
    getPermissionsList() {
      return this.permissionsList;
    },
    getModalList() {
      return this.currentActiveModalList?.lists
        ?.filter((el) => el.name?.toLowerCase()?.includes(this.searchValues.toLowerCase()));
    },
  },

  watch: {
    showAddRoleModal: {
      async handler(val) {
        if (val) {
          await this.handleShowModal();
        }
      }
    }
  },

  async mounted() {
    await this.fetchRolesList();
  },

  methods: {
    async fetchRolesList() {
      this.loadingStates.fetchingRoles = true;
        try {
          const queryParams = makeQueryParams(this.serverParams, this.currentPageData);
          const { data } = await axios.get(`${this.baseApiURL}${this.baseUrlPostfix}?${queryParams}`);
          this.rows = data;
        } catch (error) {
          console.log(error, ">>>>error");
        } finally {
          this.loadingStates.fetchingRoles = false;
        }
    },

    async createRole({ roles_and_permission }) {
      const trimmedName = roles_and_permission.name.trim()
      if (!trimmedName.length) return;
      this.loadingStates.createRole = true;

      try {
        const { data } = await axios.post(`${this.baseApiURL}${this.baseUrlPostfix}`, {
          name: trimmedName,
          description: roles_and_permission.description.trim(),
          permissions: this.permissionsList
        });

        if (this.isTenant && data?.data?.id) {
          this.$router.push({
            name: "roles-and-permissions-details",
            params: { id: data.data.id },
          });
        }
        this.fetchRolesList();
        this.$toast.success(data.message || "Role created!");
      } catch (error) {
        console.log(error, ">>>>error");
        this.$toast.error(error.response.data.detail || "Failed to created role!");
      } finally {
        this.loadingStates.createRole = false;
        this.handleCloseModal();
      }
    },

    async deleteRole(role_id) {
      const promise = await this.$refs["confirm-popup"].show({
        title: "Are you sure?",
        message: "This Role  will be deleted permanently. Please confirm to continue deleting this role.",
        buttonText: "Confirm",
      });

      if (promise) {
        this.loadingStates.deleteRole = true;
        try {
          const { data } = await axios.delete(`${this.baseApiURL}/${role_id}`);
          this.rows = this.rows.filter((el) => el.role_id !== role_id);
          this.$toast.success(data.message || "Role deleted!");
        } catch (error) {
          console.log(error, ">>>>error");
          this.$toast.error(error.response.data.detail || "Failed to delete role!");
        } finally {
          this.loadingStates.deleteRole = false;
          this.$refs["confirm-popup"].close();
        }
      }
    },

    async fetchPermissions() {
      this.loadingStates.permission = true;
      try {
        const { data } = await axios.get(`${this.baseApiURL}/permissions`);
        this.permissionsList = data;
      } catch (error) {
        console.log(error, ">>>>error");
      } finally {
        this.loadingStates.permission = false;
      }
    },

    async showList({ row, type }) {
      this.$refs["roles-permission-user-group-modal"].showListModal();
      this.currentActiveModalList = { lists: [], title: "" };
      this.currentActiveModalList.title = type;
      await this.fetchModalData(row.role_id, type.toLowerCase());
    },

    async fetchModalData(role_id, type) {
      this.loadingStates.listModal = true;

      try {
        const { data } = await axios.get(`${this.baseApiURL}/${role_id}/${type}`);
        if (type.toLowerCase() === "permissions") {
          this.currentActiveModalList.lists = getPermissions(data);
        } else {
          this.currentActiveModalList.lists = data.filter((el) => el.selected);
        }
      } catch (error) {
        console.log(error, "<<<<error while fetching users");
      } finally {
        this.loadingStates.listModal = false;
      }
    },

    async handleShowModal() {
      this.$refs["add-role-permission-modal"].showModal();
      await this.fetchPermissions();
    },

    handleCloseModal() {
      this.$refs["add-role-permission-modal"].closeModal();
      this.$emit("closeAddRoleModal")
    },

    handleRoleEditClick(role_id) {
      if (this.isTenant) {
        return this.$router.push({
          name: "roles-and-permissions-details",
          params: { id: role_id }
        });
      }

      this.$emit("onRoleEditClick", role_id);
    },
    searchFilter(column, value) {
      this.$emit("search-filter", { column, value });
    },

    async onColumnFilter({ columnFilters }) {
      this.currentPageData.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchRolesList();
    },

    async clearFilters() {
      this.serverParams = null;
      await this.fetchRolesList();
    },

    async onPageChange() {
      await this.$nextTick();
      const scrollTarget = document.getElementById("roleList");
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },

    handleFilterResult(event) {
      this.searchValues = event;
    },

    selectAllPer ({ input, index, all }) {
      this.permissionsList = selectAllChildPermissions(
        this.permissionsList,
        { input: input.target.checked, index, all }
      );
    },

    viewPermissionsInfo(event) {
      this.currentPermission = null;
      if (event && event.description) {
        this.currentPermission = event;
        this.openDrawer();
      }
    },

    openDrawer() {
      this.$refs.drawer.open();
    },

    closeDrawer() {
      this.$refs.drawer.close();
    },
  }
}
</script>
